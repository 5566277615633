<template>
  <div class="ebook-reader">
    <div id="read"></div>
    <div class="ebook-reader-mask"
         @touchmove="move"
         @touchend="moveEnd"
         @click="onMaskClick"
         @mousedown.left="onMouseEnter"
         @mousemove.left="onMouseMove"
         @mouseup.left="onMouseEnd"></div>
    <!-- <div class="read-wrapper">
    </div> -->
  </div>
</template>

<script>
import Epub from 'epubjs'

import { ebookMixin } from '@/utils/mixin'
import { flattening } from '@/utils/book'
import { getFontFamily, saveFontFamily, getFontSize, saveFontSize, getTheme, saveTheme, getLocation } from '@/utils/localStorage'
import { getLocalForage } from '../../utils/localForage'

global.epub = Epub
export default {
  name: 'EbookReader',
  mixins: [ebookMixin],
  methods: {
    // 1 - 鼠标进入
    // 2 - 鼠标进入后的移动
    // 3 - 鼠标从移动状态松手
    // 4 - 鼠标还原
    onMouseEnd(e) {
      if (this.mouseState === 2) {
        this.setOffsetY(0)
        this.firstOffsetY = null
        this.mouseState = 3
      } else {
        this.mouseState = 4
      }
      const time = e.timeStamp - this.mouseStartTime
      if (time < 200) {
        this.mouseState = 4
      }
      e.preventDefault()
      e.stopPropagation()
    },
    onMouseMove(e) {
      if (this.mouseState === 1) {
        this.mouseState = 2
      } else if (this.mouseState === 2) {
        let offsetY = 0
        if (this.firstOffsetY) {
          offsetY = e.clientY - this.firstOffsetY
          this.setOffsetY(offsetY)
        } else {
          this.firstOffsetY = e.clientY
        }
      }
      e.preventDefault()
      e.stopPropagation()
    },
    onMouseEnter(e) {
      this.mouseState = 1
      this.mouseStartTime = e.timeStamp
      e.preventDefault()
      e.stopPropagation()
    },
    move(e) {
      let offsetY = 0
      if (this.firstOffsetY) {
        offsetY = e.changedTouches[0].clientY - this.firstOffsetY
        this.setOffsetY(offsetY)
      } else {
        this.firstOffsetY = e.changedTouches[0].clientY
      }
      e.preventDefault()
      e.stopPropagation()
    },
    moveEnd(e) {
      this.setOffsetY(0)
      this.firstOffsetY = null
    },
    onMaskClick(e) {
      if (this.mouseState && (this.mouseState === 2 || this.mouseState === 3)) {
        return
      }
      const offsetX = e.offsetX
      const width = window.innerWidth
      if (offsetX > 0 && offsetX < width * 0.3) {
        this.prevPage()
      } else if (offsetX > 0 && offsetX > width * 0.7) {
        this.nextPage()
      } else {
        this.toggleTitleAndMenu()
      }
    },
    prevPage() {
      this.rendition && this.rendition.prev().then(() => {
        this.refreshLocation()
      })
      this.hideTitleAndMenu()
    },
    nextPage() {
      this.rendition && this.rendition.next().then(() => {
        this.refreshLocation()
      })
      this.hideTitleAndMenu()
    },
    // 点击屏幕隐藏
    toggleTitleAndMenu() {
      if (this.menuVisible) {
        this.setSettingVisible(-1)
        this.setFontFamilyVisible(false)
      }
      this.setMenuVisible(!this.menuVisible)
    },
    initFontSize() {
      const fontSize = getFontSize(this.fileName)
      if (!fontSize) {
        // 没获取到就存储默认字体
        saveFontSize(this.fileName, this.defaultFontSize)
      } else {
        this.rendition.themes.fontSize(fontSize)
        this.setDefaultFontSize(fontSize)
      }
    },
    initFontFamily() {
      const font = getFontFamily(this.fileName)
      if (!font) {
        // 没获取到就存储默认字体
        saveFontFamily(this.fileName, this.defaultFontFamily)
      } else {
        this.rendition.themes.font(font)
        this.setDefaultFontFamily(font)
      }
    },
    initTheme() {
      let defaultTheme = getTheme(this.fileName)
      if (!defaultTheme) {
        // 没获取到就存储默认字体
        defaultTheme = this.themeList[0].name
        saveTheme(this.fileName, defaultTheme)
      }
      this.setDefaultTheme(defaultTheme)
      // 注册theme主题
      this.themeList.forEach(theme => {
        this.rendition.themes.register(theme.name, theme.style)
      })
      this.rendition.themes.select(defaultTheme)
    },
    initRendition() {
      // 初始化rendition
      this.rendition = this.book.renderTo('read', {
        width: window.innerWidth,
        height: window.innerHeight,
        methods: 'default'
      })
      const location = getLocation(this.fileName)
      this.display(location, () => {
        // 初始化完，获取用户本地存储的阅读设置
        this.initFontFamily()
        this.initFontSize()
        this.initTheme()
        this.initGlobalStyle()
        this.refreshLocation()
      })
      // 导入外部字体文件给iframe中的dom
      this.rendition.hooks.content.register(contents => {
        // addStylesheet内部需传入url地址
        Promise.all([
          contents.addStylesheet(`${process.env.VUE_APP_RES_URL}/fonts/cabin.css`),
          contents.addStylesheet(`${process.env.VUE_APP_RES_URL}/fonts/daysOne.css`),
          contents.addStylesheet(`${process.env.VUE_APP_RES_URL}/fonts/montserrat.css`),
          contents.addStylesheet(`${process.env.VUE_APP_RES_URL}/fonts/tangerine.css`)
        ]).then(() => {})
      })
    },
    initGesture() {
      // epub提供的绑定事件在ifrema上方法
      this.rendition.on('touchstart', event => {
        this.touchStartX = event.changedTouches[0].clientX // 单手指点击的位置
        this.touchStartTime = event.timeStamp // 刚触碰的时间
      })
      this.rendition.on('touchend', event => {
        const offsetX = event.changedTouches[0].clientX - this.touchStartX // 手指滑动的偏移量
        const time = event.timeStamp - this.touchStartTime // 手指在屏幕上停留的时间
        if (time < 800 && offsetX > 40) {
          this.prevPage()
        } else if (time < 800 && offsetX < -40) {
          this.nextPage()
        } else {
          this.toggleTitleAndMenu()
        }
        // 禁止默认事件和事件传播
        // event.preventDefault()
        event.stopPropagation()
      })
    },
    parseBook() {
      // display -> refreshLocation
      // 获取封面信息
      this.book.loaded.cover.then(cover => {
        // console.log(Object.values(this.book.archive.urlCache))
        if (cover) {
          this.book.archive.createUrl(cover).then(url => {
            // 获取Blob URL, 写入缓存
            this.setCover(url)
          })
        } else {
          // this.setCover(this.book.archive.urlCache['/cover.jpg'])
          this.book.archive.createUrl('/cover.jpg').then(url => {
            // 获取Blob URL, 写入缓存
            this.setCover(url)
          })
        }
      })
      // 获取标题和作者信息
      this.book.loaded.metadata.then(metadata => {
        this.setMetadata(metadata)
      })
      // 获取目录
      this.book.loaded.navigation.then(nav => {
        const navItem = flattening(nav.toc)
        // 目录分级
        function findLevel(item, level = 0) {
          return !item.parent ? level : findLevel(navItem.filter(parentItem => parentItem.id === item.parent)[0], ++level)
        }
        navItem.forEach(item => {
          item.level = findLevel(item)
        })
        this.setNavigation(navItem)
      })
    },
    initEpub(url) {
      // console.log(this.fileName)
      this.book = new Epub(url)
      this.setCurrentBook(this.book)
      this.initRendition()
      this.initGesture()
      this.parseBook()
      this.book.ready.then(() => {
        // 每页字符 * 屏幕实际宽度/标准宽度 * 实际字体大小/标准字大小
        return this.book.locations.generate(750 * (window.innerWidth / 375) * getFontSize(this.fileName) / 16).then(locations => {
            console.log('locations:', locations)
            // 拿到页数信息
            this.navigation.forEach(nav => {
              nav.pageList = []
            })
            locations.forEach(item => {
              const loc = item.match(/\[(.*)\]!/) && item.match(/\[(.*)\]!/)[1]
              this.navigation.forEach(nav => {
              if (nav.href) {
                // |xhtml
                const href = nav.href.match(/^(.*)\.html$/)
                // console.log('nav.href:', nav.href, 'href:', href)
                if (href) {
                  if (href[1] === loc) {
                    nav.pageList.push(item)
                  }
                }
              }
              })
              let currentPage = 1
              this.navigation.forEach((nav, index) => {
                if (index === 0) {
                  nav.page = 1
                } else {
                  nav.page = currentPage
                }
                currentPage += nav.pageList.length + 1
              })
            })
          // console.log('---', this.navigation)
            this.setPagelist(locations)
            this.setBookAvailable(true)
            // 分页完成后调用
            this.refreshLocation()
          })
        })
    }
  },
  mounted () {
    const books = this.$route.params.fileName.split('|')
    const fileName = books[1]
    getLocalForage(fileName, (err, blob) => {
      if (!err && blob) {
        // console.log('找到离线缓存电子书')
        this.setFileName(books.join('/')).then(() => {
          this.initEpub(blob)
        })
      } else {
        // console.log('在线获取电子书')
        this.setFileName(this.$route.params.fileName.split('|').join('/')).then(() => {
          const url = process.env.VUE_APP_EPUB_URL + '/' + this.fileName + '.epub'
          this.initEpub(url)
        })
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/global";
  .ebook-reader {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .ebook-reader-mask {
      position: absolute;
      z-index: 150;
      background: transparent;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
</style>
